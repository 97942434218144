import {AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatSidenav, MatSidenavModule} from '@angular/material/sidenav';
import {Router, RouterModule} from '@angular/router';
import {AppAuthService} from './common/auth';
import {BreakpointObserver} from '@angular/cdk/layout';
import {combineLatest} from 'rxjs';
import {AppState} from './common/app-state';
import {Company, CompanySite} from './model';

@Component({
  selector: 'app-side-nav',
  imports: [CommonModule, MatExpansionModule, MatIconModule, MatListModule, MatSidenavModule, RouterModule],
  templateUrl: 'side-nav.component.html',
  styleUrls: ['side-nav.component.scss']
})
export class SideNavComponent implements OnInit, AfterViewInit {
  @ViewChild('sidenav') sidenav!: MatSidenav;
  companies: Company[] = [];
  company?: Company;
  site?: CompanySite;
  wide: boolean = true;
  help: boolean = true;

  constructor(private changeDetector: ChangeDetectorRef, private breakpointObserver: BreakpointObserver,
              public authService: AppAuthService, private appState: AppState, private router: Router) {
  }

  get isDashboard(): boolean {
    return this.router.url?.endsWith('/dashboard');
  }

  ngOnInit(): void {
    this.appState.companies$.subscribe((companies) => {
      this.companies = companies;
    });
    this.appState.companySite$.subscribe(({company, site}) => {
      this.company = company;
      this.site = site;
    });
    this.appState.wideMode$.subscribe((value) => this.wide = value);
    this.appState.helpMode$.subscribe((value) => this.help = value);
  }

  ngAfterViewInit() {
    combineLatest([this.breakpointObserver.observe('(min-width: 660px)'), this.authService.isLoggedIn$])
      .subscribe(([{matches}, loggedIn]) => {
        this.sidenav.opened = matches && loggedIn;
        this.sidenav.mode = matches ? 'side' : 'over';
        this.changeDetector.detectChanges();
      });
  }

  toggle() {
    if (this.authService.isLoggedIn) {
      this.sidenav.toggle();
    }
  }
}
