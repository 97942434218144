import {Component, inject, OnInit} from '@angular/core';
import {MatCardModule} from '@angular/material/card';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {SocialAuthService} from '@abacritt/angularx-social-login';
import {AppAuthService} from './app-auth.service';
import {Router} from "@angular/router";
import {FormActionsComponent} from "../form-actions.component";

@Component({
  selector: 'app-waiting',
  templateUrl: 'waiting.component.html',
  styleUrl: 'login-reset-register.component.scss',
  imports: [MatCardModule, MatButtonModule, MatIconModule, FormActionsComponent]
})
export class WaitingComponent implements OnInit {
  authService = inject(AppAuthService);
  socialAuthService = inject(SocialAuthService);
  router = inject(Router);

  ngOnInit() {
    this.authService.user$.subscribe(async user => {
      if (!user?.locked) {
        await this.router.navigate(['/']);
      }
    });
  }


  schedulaCall() {
    const user = this.authService.user;
    if (!user) {
      return;
    }
    window.location.href = `https://meetings-eu1.hubspot.com/gunther-hentschel?email=${encodeURIComponent(user.email)}`;
  }
}
