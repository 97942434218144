import {enableProdMode, ErrorHandler, importProvidersFrom, LOCALE_ID} from '@angular/core';
import {environment} from './environments/environment';
import {AppComponent} from './app/app.component';
import {ToastrModule} from 'ngx-toastr';
import {provideAnimations} from '@angular/platform-browser/animations';
import {bootstrapApplication} from '@angular/platform-browser';
import {HttpClient, provideHttpClient, withInterceptors} from '@angular/common/http';
import {MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material/core';
import {registerLocaleData} from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import localeDeExtra from '@angular/common/locales/extra/de';
import localeEnExtra from '@angular/common/locales/extra/en';
import {CustomErrorHandler} from './app/common/custom-error.handler';
import {AppRoutingModule} from './app/app-routing.module';
import {MatDialogModule} from '@angular/material/dialog';
import {authenticationInterceptor} from './app/common/auth/authenticationInterceptor';
import {loadSocialAuthConfig} from './app/common/auth/load-social-auth.config';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import {loaderInterceptor} from './app/common/auth/loader.interceptor';
import {LoadingBarModule} from '@ngx-loading-bar/core';
import {versionInterceptor} from './app/common/auth/version.interceptor';

registerLocaleData(localeDe, 'de-DE', localeDeExtra);
registerLocaleData(localeEn, 'en-US', localeEnExtra);

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      LoadingBarModule,
      ToastrModule.forRoot({
        preventDuplicates: true,
        progressBar: true,
        timeOut: 5000,
        enableHtml: true,
        positionClass: 'toast-bottom-right'
      }),
      MatDialogModule,
      MatNativeDateModule,
      AppRoutingModule,
    ),
    {provide: MAT_DATE_LOCALE, useValue: 'en-US'},
    {provide: LOCALE_ID, useValue: 'en-US'},
    {provide: 'SocialAuthServiceConfig', useFactory: loadSocialAuthConfig, deps: [HttpClient]},
    {provide: ErrorHandler, useClass: CustomErrorHandler},
    {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'outline', subscriptSizing: 'dynamic'}},
    provideHttpClient(withInterceptors([
      authenticationInterceptor, loaderInterceptor, versionInterceptor
    ])),
    provideAnimations()
  ]
}).then().catch(err => console.log(err));
