import {HttpInterceptorFn} from '@angular/common/http';
import {inject} from '@angular/core';
import {LoadingBarService} from '@ngx-loading-bar/core';
import {finalize} from 'rxjs/operators';

export const loaderInterceptor: HttpInterceptorFn = (req, next) => {
  // do not show loader for file-uploads
  if (req.method === 'POST' && (req.url === 'api/document' || req.url === 'api/location/shx-upload')) {
    return next(req);
  }

  const loader = inject(LoadingBarService).useRef();
  loader.start();
  return next(req).pipe(finalize(() => loader.complete()));
};
