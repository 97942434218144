import {Component, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CanaryService} from './canary.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-canary',
  imports: [CommonModule],
  template: `
    <div (click)="canary.disable()" *ngIf="canary.enabled" class="canary">
      canary-version<br/> exit test
    </div>
  `,
  styles: [`
    .canary {
      opacity: 0.5;
      text-align: center;
      font-size: 12px;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 0;
      border: 0.5px solid #f22;
      background: #fdd;
      color: #000;
      padding: 0 5px;
    }
  `]
})
export class CanaryComponent implements OnInit {
  constructor(private route: ActivatedRoute, public canary: CanaryService) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(({canary}) => {
      if (canary != undefined) {
        this.canary.enable();
      }
    });
  }
}
