<mat-card>
  <mat-card-header>
    <h1 mat-card-title>Thank you for registering with supplycanvas!</h1>
  </mat-card-header>

  <mat-card-content>

    <div>
      We're excited to have you! Schedule a quick video call to unlock your full account and ensure supplycanvas is
      tailored to your goals.<br/>
      This call allows us to better understand your goals and ensure supplycanvas is perfectly set
      up to meet your needs.
    </div>

    <div>
      Scheduling is easy - simply choose a time that works best for you. After the call, you will receive an activation
      link via email, giving you full access to your account!
    </div>

    <form-actions>
      <button (click)="schedulaCall()" color="primary" mat-raised-button>
        <mat-icon>schedule</mat-icon>
        pick a time
      </button>
    </form-actions>
  </mat-card-content>
</mat-card>
