import {ErrorHandler, inject, Injectable, Injector, NgZone} from '@angular/core';
import {Router} from '@angular/router';
import {HttpErrorResponse} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';
import * as StackTrace from 'stacktrace-js';

@Injectable()
export class CustomErrorHandler implements ErrorHandler {
  private injector = inject(Injector)
  private zone = inject(NgZone)
  private router = inject(Router)

  async handleError(error: any) {
    if (error.rejection) {
      error = error.rejection;
    }
    const toastr = this.injector.get(ToastrService);
    if (error?.message?.startsWith('Failed to fetch dynamically imported module')) {
      console.error('trigger reload!');
      this.zone.runOutsideAngular(() => location.reload());
    } else if (error instanceof TypeError) {
      await this.showTypeError(error)
    } else if (error instanceof HttpErrorResponse) {
      if (navigator.onLine) {
        this.zone.run(() => toastr.error(
          error.message + `<div class="pre">${JSON.stringify(error.error, undefined, 2)}</div>`,
          'Error on page ' + this.router.url, {disableTimeOut: true}));
      } else {
        this.zone.run(() => toastr.error(undefined, "No Internet Connection", {disableTimeOut: true}));
      }
    } else {
      this.zone.run(() => toastr.error(
        error?.messge || error?.toString() || 'An unknown error occurred',
        'Error on page ' + this.router.url, {disableTimeOut: true}));
    }
  }

  async showTypeError(error: TypeError) {
    console.error(error);
    // skip consecutive type errors
    this.showTypeError = async (error: TypeError) => console.error(error);

    const container = document.createElement('div');
    container.id = 'error-container';

    const message = document.createElement('h4');
    message.textContent = error.name + ': ' + (error.message || 'An unknown type error occurred');
    container.appendChild(message);

    const detail = document.createElement('pre');
    detail.textContent = (await StackTrace.fromError(error)).map(sf => sf.toString()).join('\n');
    container.appendChild(detail);
    document.body.appendChild(container);
  }
}
