import {inject, NgModule} from '@angular/core';
import {ActivatedRouteSnapshot, RouterModule, Routes} from '@angular/router';
import {authenticatedGuard} from './common/auth/authenticated.guard';
import {notAuthenticatedGuard} from './common/auth/not-authenticated.guard';
import {provideMatomo, withRouter} from 'ngx-matomo-client';
import {companyDetailResolver} from './common/service/company-detail.resolver';
import {GptTestComponent} from './gpt-test/gpt-test.component';
import {DashboardComponent} from './dashboard-test/dashboard.component';
import {WaitingComponent} from './common/auth/waiting.component';
import {ReportService} from './common/service/report.service';
import {ArchiveComponent} from "./company/archive/archive.component";

const routes: Routes = [
  {path: '', pathMatch: 'full', redirectTo: '/dashboard'},
  {path: '', canActivate: [notAuthenticatedGuard], loadChildren: () => import('./common/auth')},
  {path: 'waiting', canActivate: [authenticatedGuard], component: WaitingComponent},
  {
    path: 'archive/:archiveId', canActivate: [authenticatedGuard], component: ArchiveComponent,
    resolve: {
      list: async (route: ActivatedRouteSnapshot) => ({
        totalElements: 1, content: [await inject(ReportService).detailArchive(route.params[`archiveId`])]
      })
    },
    data: {openFirstDocument: true}
  },
  {path: 'help', canActivate: [authenticatedGuard], loadChildren: () => import('./help'), data: {helpMode: true}},
  {
    path: 'dashboard', canActivate: [authenticatedGuard], loadChildren: () => import('./dashboard'),
    resolve: {companySite: companyDetailResolver}
  },
  {
    path: 'invite', canActivate: [authenticatedGuard], loadChildren: () => import('./invite'),
    resolve: {companySite: companyDetailResolver}
  },
  {path: 'user', canActivate: [authenticatedGuard], loadChildren: () => import('./user')},
  {path: 'team', canActivate: [authenticatedGuard], loadChildren: () => import('./team')},
  {path: 'company', canActivate: [authenticatedGuard], loadChildren: () => import('./company')},
  {path: 'admin', canActivate: [authenticatedGuard], loadChildren: () => import('./admin')},
  {path: 'gpt-test', canActivate: [authenticatedGuard], component: GptTestComponent, data: {wideMode: true}},
  {path: 'dashboard-test', canActivate: [authenticatedGuard], component: DashboardComponent, data: {wideMode: true}}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  providers: [
    provideMatomo({
      acceptDoNotTrack: false,
      trackerUrl: '/api/',
      trackerUrlSuffix: 'stats',
      scriptUrl: '/api/stats',
      enableJSErrorTracking: true,
      siteId: 7
    }, withRouter())
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
